import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"

const NotFoundPage = () => {

  const { background } = useStaticQuery(
    graphql`
        query {
            background: file(relativePath: {eq: "background.webp"}) {
                childImageSharp {
                gatsbyImageData(
                    width: 4176,
                    placeholder: BLURRED,
                    formats: [AUTO, WEBP, AVIF],
                    quality: 100,
                    webpOptions: {quality: 100}
                )
                }
            }
        }
    `
)

const pluginImage = getImage(background)
const bgImage = convertToBgImage(pluginImage)

return (
  <BackgroundImage
    Tag="section"
    {...bgImage}
    preserveStackingContext>
  <Layout>
      <Seo title="404: Not found" />
      

      <div className="flex-container">
        <div className="not-found">
          <h1>404</h1>
          <h2>This page doesn't exist.</h2>
          <p>Sorry, we can’t find the page you were looking for, <Link to="/">click here</Link> to go home.</p>
        </div>
      </div>

      

    </Layout>
  </BackgroundImage>
  )
}

export default NotFoundPage
